import { http } from 'common/helpers/axios/axios-helper';
import { SignInAudit as Model, SignInAuditQuery as Query } from '../model/sign-in-audit.model';

const basePath = 'user/signin/audit';

async function getDataList(query?: Query) {
	const queryString = '';
	return await http.get<Model[]>(`${basePath}${queryString}`);
}

export const SignInAuditService = { getDataList };
