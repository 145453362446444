import i18next from 'i18next';
import DataTable, { TableColumn } from 'react-data-table-component';
import { toast } from 'react-toastify';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { tableHelper } from 'common/helpers/table/table-helper';
import { StatusFilterDropdown } from 'common/partials/StatusFilterDropdown';
import {
	MasterLayout,
	MainLayout,
	ToolbarLayout,
	ToolbarLayoutLeft,
	ToolbarLayoutRight,
} from 'common/imports/content-layout';
import { itLabTableStyle } from 'common/helpers/table/table-style';
import { RolesDictionary } from 'common/helpers/dictionaries/roles-dictionary';
import ProtectedComponent from 'common/partials/ProtectedComponent';
import en from '../i18n/en-us.json';
import pt from '../i18n/pt-br.json';
import es from '../i18n/es-es.json';
import { SignInAuditService as Service } from '../services/sign-in-audit.services';
import { SignInAudit as Model, SignInAuditQuery as Query } from '../model/sign-in-audit.model';
import { formatLocalizedDateTime } from 'common/helpers/dateUtils';

export function SignInAudit() {
	i18next.addResourceBundle('us', 'translation', en);
	i18next.addResourceBundle('br', 'translation', pt);
	i18next.addResourceBundle('es', 'translation', es);

	const { t } = useTranslation();
	const navigate = useNavigate();
	const [tableData, setTableData] = useState<Model[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	const fetchData = async (query?: Query) => {
		try {
			setIsLoading(true);
			const response = await Service.getDataList(query);
			console.log(response);
			if (response && !response.hasErrors) {
				setTableData(response.data);
			}
		} catch (err) {
			toast.error(`${t('crud.read.errorMessage')}`);
		} finally {
			setIsLoading(false);
		}
	};

	const columns: TableColumn<Model>[] = [
		{
			name: `${t('table.when')}`,
			selector: (row) => formatLocalizedDateTime(row.when.toLocaleString(), 'llll') || '',
			sortable: true,
		},
		{
			name: `${t('table.username')}`,
			selector: (row) => row.userName || '',
			sortable: true
		},
		{
			name: `${t('table.tenant')}`,
			selector: (row) => row.tenant || '',
			sortable: true
		},
		{
			name: `${t('table.success')}`,
			selector: (row) => row.success ? t('table.success.true') : t('table.success.false'),
			sortable: true
		},
	];

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<MasterLayout>
			<ToolbarLayout>
				<ToolbarLayoutLeft />
				<ToolbarLayoutRight>
					<div className="d-flex align-items-center gap-2">
						<ProtectedComponent action='hide' role={[RolesDictionary.Infrastructure.OperationSystems_Update]}>
							<Link to="/masterdata/operationsystem/add" className="btn btn-sm btn-primary fw-bolder">
								{t('addDataButton')}
							</Link>
						</ProtectedComponent>
					</div>
				</ToolbarLayoutRight>
			</ToolbarLayout>
			<MainLayout>
				<div className="card card-flush">
					<div className="card-body">
						<DataTable
							//title={selectedRows.length === 0 ? false : <></>}
							persistTableHead
							columns={columns}
							data={tableData}
							noDataComponent={tableHelper.getNoDataComponent()}
							pagination
							paginationComponentOptions={tableHelper.getPaginationComponentOptions()}
							paginationPerPage={tableHelper.defaultRowsPerPage}
							paginationRowsPerPageOptions={tableHelper.paginationRowsPerPageOptions}
							selectableRows
							selectableRowsHighlight
							dense
							highlightOnHover
							progressPending={isLoading}
							progressComponent={tableHelper.getLoader()}
							//contextActions={contextActions}
							contextMessage={tableHelper.getContextMessage()}
							//onSelectedRowsChange={handleRowSelected}
							//clearSelectedRows={toggleCleared}
							selectableRowsVisibleOnly
							customStyles={itLabTableStyle}
						/>
					</div>
				</div>
			</MainLayout>
		</MasterLayout>
	);
}
